import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import {ModalService} from '../../../providers/modal/modal-service.service';
import {TranslateService} from '../../../providers/translate/translate.service';
import {Subscription} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';
import {LoadingStoreActions, RootStoreState} from '../../../reducers';

@Component({
    selector: 'app-modal-video-round',
    templateUrl: './modal-video-round.component.html',
    styleUrls: ['./modal-video-round.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.25s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.25s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.25s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
    ]
})
export class ModalVideoRoundComponent implements OnInit {
    data: any;
    private subs: Array<Subscription> = [];
    public lgdata: any;
    videoSrc: string;
    videoSrc2: string;
    showVideo: boolean;
    audioPlayP: boolean;
    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private store$: Store<RootStoreState.State>) {
        this.videoSrc = '';
        this.showVideo = true;
    }

    ngOnInit() {
        document.getElementById('content').scrollTop = 0;
        if ((this as any).inputs && (this as any).inputs.videoSrc2) {
            this.videoSrc = (this as any).inputs.videoSrc2;
        }
        if ((this as any).inputs && (this as any).inputs.audioPlayP) {
            // this.audioPlayP = (this as any).inputs.audioPlayP;
        }
        // const audio = document.getElementById('audio');
        // @ts-ignore
        // audio.pause();
        this.lgdata = this.translateService.data;
        document.getElementById('content').classList.add('locked');
        /*this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
            audioPlay: false
        }));*/
    }

    ngAfterViewInit() {
        const vid = document.getElementById('video');
        // @ts-ignore
        vid.volume = 0;
        // @ts-ignore
        vid.play();
    }

    closeDialog() {
        const vid = document.getElementById('backgroundVideo');
        vid.style.filter = 'opacity(75%)';
        this.showVideo = false;
        /*if (this.audioPlayP) {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: true
            }));
            const audio = document.getElementById('audio');
            // @ts-ignore
            audio.play();
        }*/
        setTimeout(() => {
            document.getElementById('content').classList.remove('locked');
            this.modalService.destroy();
        }, 850);
        setTimeout(() => {
            this.modalService.destroy();
        }, 1500);
    }

}
