export interface State {
    isLoading: boolean;
    error: string;
    component: string;
    i18n: any;
    counters: {
        items: number;
        keyWords: number;
    };
    videoSrc: string;
    audioPlay: boolean;
    allowScroll: boolean;
    videoSrc2: string;
}

export const initialState: State = {
    isLoading: false,
    error: null,
    allowScroll: true,
    audioPlay: true,
    component: '',
    i18n: {},
    counters: {
        items: 0,
        keyWords: 0
    },
    videoSrc: '',
    videoSrc2: '',
};
