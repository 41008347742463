import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { loadingReducer } from './loading-store.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('loading', loadingReducer)
  ],
  providers: []
})
export class LoadingStoreModule { }
