import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {TranslateService} from '../../providers/translate/translate.service';
import {LoadingStoreActions, LoadingStoreSelectors, RootStoreState} from '../../reducers';
import {animate, style, transition, trigger} from '@angular/animations';
import {ModalService} from '../../providers/modal/modal-service.service';

@Component({
    selector: 'app-video-player-5',
    templateUrl: './video-player-5.component.html',
    styleUrls: ['./video-player-5.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.20s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.20s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.40s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.50s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '12px'}),
                        animate('0.50s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})


export class VideoPlayer5Component implements OnInit, OnDestroy {
    private subs: Array<Subscription> = [];
    videoSrc: string;
    text = {
        0: true,

    };
    last: number;
    duration: number;
    tmpYOffset: number;
    isScrolling2: boolean;
    totalElement: number;
    step: number;
    discoverElement2: boolean;
    adv: number;
    easterEgg: boolean;
    time: number;
    eventLister: any;
    player: boolean;
    showVideoVar: boolean;

    constructor(
        private translateService: TranslateService,
        private modalService: ModalService,
        private store$: Store<RootStoreState.State>) {
        this.videoSrc = '';
        this.duration = 0;
        this.tmpYOffset = 0;
        this.isScrolling2 = false;
        this.totalElement = 10;
        this.step = 0;
        this.discoverElement2 = false;
        this.adv = 0;
        this.easterEgg = false;
        this.time = 0;
        this.player = true;
        this.showVideoVar = true;
    }

    ngOnInit() {
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectVideoSrc).subscribe((src) => {
            if (src) {
                this.videoSrc = src;
            }
        }));
    }

    ngAfterViewInit() {
        const vid = document.getElementById('backgroundVideo');
        // @ts-ignore
        vid.muted = true;
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            // @ts-ignore
            vid.play();
            // @ts-ignore
            vid.pause();
            // @ts-ignore
            vid.controls = true;
        }
        this.player = false;
        // const logo = document.getElementById('logo');
        // logo.classList.add('hide');
        vid.style.filter = 'opacity(100%)';
        // @ts-ignore
        vid.muted = false;
        // @ts-ignore
        // vid.play();
    }

    goTo(where: string) {
        this.showVideoVar = false;
        setTimeout(() => {
            this.store$.dispatch(new LoadingStoreActions.ComponentLoadAction({component: where}));
        }, 1000);
    }


    ngOnDestroy() {
        this.subs.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }

}
