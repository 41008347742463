import { createSelector, MemoizedSelector } from '@ngrx/store';
// import { AuthenticationStoreSelectors } from './authentication-store';
import { LoadingStoreSelectors } from './loading-store';

export const selectError: MemoizedSelector<object, string> = createSelector(
    // AuthenticationStoreSelectors.selectAuthError,
    LoadingStoreSelectors.selectLoadingError,
    // DashboardStoreSelectors.selectDashboardError,
    (/*authenticationError: string,*/ loadingError: string) => {
        return /*authenticationError ||*/ loadingError;
    }
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
    // AuthenticationStoreSelectors.selectAuthIsLoading,
    LoadingStoreSelectors.selectLoadingIsLoading,
    (/*authenticationIsLoading: boolean,*/ loadingIsLoading: boolean) => {
        return /*authenticationIsLoading ||*/ loadingIsLoading;
    }
);
