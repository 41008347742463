import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { EffectsModule } from '@ngrx/effects';
import { StoreModule, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { AuthenticationStoreModule } from './authentication-store';
import { LoadingStoreModule } from './loading-store';
import { storeFreeze } from 'ngrx-store-freeze';
import { State } from './root-state';
// import { authenticationReducer } from './authentication-store/authentication-store.reducer';
import { loadingReducer } from './loading-store/loading-store.reducer';
import { environment } from '../../environments/environment';

export const metaReducers: MetaReducer<State>[] = environment.production ? [] : [storeFreeze];

export const reducers: ActionReducerMap<State> = {
  loading: loadingReducer
  // authentication: authenticationReducer,

};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    environment.production ? [] : StoreDevtoolsModule.instrument({
      maxAge: 80
    }),
    // EffectsModule.forRoot([]),
    // AuthenticationStoreModule,
    LoadingStoreModule,
  ],
  declarations: []
})
export class RootStoreModule { }
