import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { RootStoreState, LoadingStoreActions } from '../../reducers';

@Injectable()

export class TranslateService {
  data: any = {};

  constructor(
    private http: HttpClient,
    private store$: Store<RootStoreState.State>) { }

  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve) => {
      const langPath = `assets/i18n/${lang || 'fr'}.json`;
      this.http.get<{}>(langPath).subscribe((translation: any) => {
        this.data = Object.assign({}, translation || {});
        // this.store$.dispatch(new LoadingStoreActions.SelectI18NAction({ i18n: translation || {} }));
        resolve();
      });
    });
  }
}
