import { Actions, ActionTypes } from './loading-store.actions';
import { initialState, State } from './loading-store.state';


export function loadingReducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.LOADING_REQUEST:
            return {
                ...state,
                error: null,
                isLoading: true
            };
        case ActionTypes.LOADING_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false
            };
        case ActionTypes.LOADING_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                isLoading: false
            };
        case ActionTypes.COMPONENT_LOAD:
            return {
                ...state,
                error: null,
                isLoading: false,
                component: action.payload.component
            };
        case ActionTypes.SET_COUNTER_ITEMS:
            return {
                ...state,
                error: null,
                isLoading: false,
                counters: {
                    ...state.counters,
                    items: action.payload.counter
                }
            };
        case ActionTypes.SET_AUDIO_PLAY:
            return {
                ...state,
                error: null,
                isLoading: false,
                audioPlay: action.payload.audioPlay
            };
        case ActionTypes.SET_VIDEO_SRC:
            return {
                ...state,
                error: null,
                isLoading: false,
                videoSrc: action.payload.videoSrc
            };
        case ActionTypes.SET_VIDEO_SRC_2:
            return {
                ...state,
                error: null,
                isLoading: false,
                videoSrc: action.payload.videoSrc2
            };
        default: {
            return state;
        }
    }
}
