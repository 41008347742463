import {isPlatformBrowser} from '@angular/common';
import {Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {SpawnerDirective} from './directives/spawner.directive';
import {VideoPlayerComponent} from './pages/video-player/video-player.component';
import {VideoPlayer2Component} from './pages/video-player-2/video-player-2.component';
import {VideoPlayer3Component} from './pages/video-player-3/video-player-3.component';
import {ModalService} from './providers/modal/modal-service.service';
import {LoadingStoreActions, LoadingStoreSelectors, RootStoreState} from './reducers';
import {TranslateService} from './providers/translate/translate.service';
import {VideoPlayer4Component} from './pages/video-player-4/video-player-4.component';
import {VideoPlayer5Component} from './pages/video-player-5/video-player-5.component';
import {VideoPlayer6Component} from "./pages/video-player-6/video-player-6.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private subs: Array<Subscription> = [];
    private isBrowser: boolean;

    @ViewChild(SpawnerDirective, {static: true}) adHost: SpawnerDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private modalService: ModalService,
        private translateService: TranslateService,
        private store$: Store<RootStoreState.State>,
        @Inject(PLATFORM_ID) private readonly platformId: any
        /*private translateService: TranslateService*/) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit() {
        this.translateService.use('fr');
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectActiveComponent).subscribe((component: string) => {
            setTimeout(() => {
                let comp: any;
                switch (component) {

                    case 'video-player': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_1'
                        }));
                        comp = VideoPlayerComponent;
                        break;
                    }

                    case 'video-player-2': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_2'
                        }));
                        comp = VideoPlayer2Component;
                        break;
                    }

                    case 'video-player-3': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_3'
                        }));
                        comp = VideoPlayer3Component;
                        break;
                    }

                    case 'video-player-4': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_4'
                        }));
                        comp = VideoPlayer4Component;
                        break;
                    }

                    case 'video-player-5': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_5'
                        }));
                        comp = VideoPlayer5Component;
                        break;
                    }

                    case 'video-player-6': {
                        this.store$.dispatch(new LoadingStoreActions.SetVideoSrcAction({
                            videoSrc: 'assets/videos/MODULE_6_1'
                        }));
                        comp = VideoPlayer6Component;
                        break;
                    }

                    default: {
                        comp = null;
                        break;
                    }
                }
                if (comp) {
                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(comp);
                    const viewContainerRef = this.adHost.viewContainerRef;
                    viewContainerRef.clear();
                    const componentRef = viewContainerRef.createComponent(componentFactory);
                }
            });
        }));
        let componentModule = 'video-player';
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const module = urlParams.get('module');
        if (module) {
            if (module === '1') {
                componentModule = 'video-player';
            } else {
                componentModule = 'video-player-' + module;
            }

        }
        this.store$.dispatch(new LoadingStoreActions.ComponentLoadAction({component: componentModule}));
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }
}
