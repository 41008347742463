import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import {VideoPlayerComponent} from './video-player.component';
import {ModalVideoComponent} from '../../components/modals/modal-video/modal-video.component';
import {TranslatePipe} from '../../pipes/translate/translate.pipe';

@NgModule({
    declarations: [VideoPlayerComponent, ModalVideoComponent, TranslatePipe],
    imports: [
        CommonModule,
        FormsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    exports: [VideoPlayerComponent],
    entryComponents: [
        ModalVideoComponent
    ]
})
export class VideoPlayerComponentModule {
}
