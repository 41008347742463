import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { State } from './loading-store.state';

const getError = (state: State): any => state ? state.error : null;
const getIsLoading = (state: State): boolean => state ? state.isLoading : false;
const getActiveComponent = (state: State): string => state ? state.component : '';
const getCounterItems = (state: State): number => state ? state.counters.items : 0;
const getAudioPlay = (state: State): boolean => state ? state.audioPlay : false;
const getScrolling = (state: State): boolean => state ? state.allowScroll : true;
const getVideoSrc = (state: State): string => state ? state.videoSrc : '';
const getVideoSrc2 = (state: State): string => state ? state.videoSrc2 : '';

export const selectLoadingState: MemoizedSelector<object, State> = createFeatureSelector<State>('loading');
export const selectLoadingError: MemoizedSelector<object, any> = createSelector(selectLoadingState, getError);
export const selectLoadingIsLoading: MemoizedSelector<object, boolean> = createSelector(selectLoadingState, getIsLoading);
export const selectActiveComponent: MemoizedSelector<object, string> = createSelector(selectLoadingState, getActiveComponent);
export const selectCounterItems: MemoizedSelector<object, number> = createSelector(selectLoadingState, getCounterItems);
export const selectAudioPlay: MemoizedSelector<object, boolean> = createSelector(selectLoadingState, getAudioPlay);
export const selectIsScrolling: MemoizedSelector<object, boolean> = createSelector(selectLoadingState, getScrolling);
export const selectVideoSrc: MemoizedSelector<object, string> = createSelector(selectLoadingState, getVideoSrc);
export const selectVideoSrc2: MemoizedSelector<object, string> = createSelector(selectLoadingState, getVideoSrc2);
