import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {TranslateService} from '../../providers/translate/translate.service';
import {LoadingStoreActions, LoadingStoreSelectors, RootStoreState} from '../../reducers';
import {animate, style, transition, trigger} from '@angular/animations';
import {ModalService} from '../../providers/modal/modal-service.service';
import {ModalVideoComponent} from '../../components/modals/modal-video/modal-video.component';
import {ModalVideoRoundComponent} from '../../components/modals/modal-video-round/modal-video-round.component';

@Component({
    selector: 'app-video-player-2',
    templateUrl: './video-player-2.component.html',
    styleUrls: ['./video-player-2.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.20s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.20s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.40s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.50s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '28px'}),
                        animate('0.75s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte2',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '20px'}),
                        animate('0.75s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte3',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '30px'}),
                        animate('2s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationEaster',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('1s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.5s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})


export class VideoPlayer2Component implements OnInit, OnDestroy {
    private subs: Array<Subscription> = [];
    videoSrc: string;
    text = {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
    };
    last: number;
    duration: number;
    tmpYOffset: number;
    isScrolling2: boolean;
    totalElement: number;
    step: number;
    discoverElement2: boolean;
    adv: number;
    easterEgg: boolean;
    time: number;
    eventLister: any;
    audioPlay: boolean;
    showVideoVar: boolean;
    counter: number;
    allowScroll: boolean;
    hasClickEG: boolean;
    clientY: any;
    constructor(
        private translateService: TranslateService,
        private modalService: ModalService,
        private store$: Store<RootStoreState.State>) {
        this.videoSrc = '';
        this.duration = 0;
        this.tmpYOffset = 0;
        this.isScrolling2 = false;
        this.totalElement = 17;
        this.step = 0;
        this.discoverElement2 = false;
        this.adv = 0;
        this.easterEgg = false;
        this.time = 0;
        this.eventLister = this.handleEvent.bind(this);
        this.showVideoVar = true;
        this.hasClickEG = false;
        this.clientY = null;
    }

    ngOnInit() {
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectVideoSrc).subscribe((src) => {
            if (src) {
                this.videoSrc = src;
            }
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectCounterItems).subscribe((counterItem: number) => {
            this.counter = counterItem;
        }));
        this.audioPlay = false;

        this.subs.push(this.store$.select(LoadingStoreSelectors.selectIsScrolling).subscribe((allowScroll: boolean) => {
            this.allowScroll = allowScroll;
        }));
    }

    getCounter() {
        return ' assets/images/COLLECTEUR_' + this.counter + '_4.svg';
    }

    handleEvent(e) {
        e.preventDefault();
        const locked = document.getElementById('content').classList.contains('locked');
        let direction;
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            if (!this.clientY) {
                this.clientY =  e.changedTouches ? e.changedTouches[0].clientY : null;
                this.isScrolling2 = true;
            } else {
                this.isScrolling2 = false;
                if (this.clientY > e.changedTouches[0].clientY) {
                    direction = 'down';
                } else {
                    direction = 'up';
                }
                this.clientY = e.changedTouches[0].clientY;
            }
        } else {
            direction = e.wheelDelta > 0 ? 'up' : 'down';
        }
        let timeout = 60;
        let timeout2 = 60;
        if (isMobile) {
            timeout = 250;
            timeout2 = 150;
        }
        if (!this.isScrolling2 && !this.discoverElement2 && !locked) {
            if (this.step !== this.totalElement + 1 && this.step !== 17) {
                this.hideText();
            }
            const vid = document.getElementById('backgroundVideo');
            vid.style.filter = 'opacity(75%)';
            // this.isScrolling = true;
            // @ts-ignore
            if (!isNaN(vid.duration) && this.duration !== 0) {
                // @ts-ignore
                this.duration = vid.duration;
                this.time = this.duration / this.totalElement;
            }
            this.isScrolling2 = true;
            if (direction === 'up') {
                setTimeout(() => {
                    // @ts-ignore
                    vid.currentTime = vid.currentTime - 0.1;
                    if (this.step === 3 || this.step === 2 || this.step === 4) {
                        const easter = document.getElementById('easter');
                        easter.style.top =  easter.offsetTop + 2 + 'px';
                        const offsetRight = window.innerWidth - easter.offsetLeft - easter.offsetWidth;
                        easter.style.right =  (offsetRight - 2) + 'px';
                    }
                    this.isScrolling2 = false;
                }, timeout);
            } else {
                setTimeout(() => {
                    this.isScrolling2 = false;
                    // @ts-ignore
                    vid.currentTime = vid.currentTime + 0.1;
                    if (this.step === 3 || this.step === 2 || this.step === 4) {
                        const easter = document.getElementById('easter');
                        easter.style.top =  easter.offsetTop - 2 + 'px';
                        const offsetRight = window.innerWidth - easter.offsetLeft - easter.offsetWidth;
                        easter.style.right =  (offsetRight + 2) + 'px';
                    }
                }, timeout2);
            }
        }
    }

    hideText() {
        let count = 0;
        // tslint:disable-next-line:forin
        for (const text in this.text) {
            if (this.step !== 16) {
                this.text[count] = false;
            }
            count = count + 1;
        }
    }

    ngAfterViewInit() {
        const vid = document.getElementById('backgroundVideo');
        // @ts-ignore
        this.duration = vid.duration;
        // @ts-ignore
        vid.muted = true;
        const scrolling = document.getElementById('content');
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            scrolling.addEventListener('wheel', this.eventLister, false);
        }
        scrolling.addEventListener('scroll', this.eventLister, false);
        scrolling.addEventListener('mousewheel', this.eventLister, false);
        scrolling.addEventListener('touchmove', this.eventLister, false);
        window.requestAnimationFrame(this.onTimeUpdate.bind(this));
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            // @ts-ignore
            vid.play();
            setTimeout(() => {
                // @ts-ignore
                vid.pause();
            }, 600);
        }
        if (this.audioPlay) {
            setTimeout(() => {
                const audio = document.getElementById('audio');
                // @ts-ignore
                // audio.volume = 0.35;
                // @ts-ignore
                // audio.play();
            }, 600);
        }
    }

    onTimeUpdate() {
        const video = document.getElementById('backgroundVideo');
        // @ts-ignore
        let step = 0;
        // @ts-ignore
        if (Math.round(video.currentTime) === 0 && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 1;
        // @ts-ignore

        if (Math.round(video.currentTime) > this.time - 1 && Math.round(video.currentTime) < this.time && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 2;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 3;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 4;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);

        }
        // @ts-ignore
        let step = 5;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 6;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 7;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 8;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
            this.showVideo(1);
        }
        // @ts-ignore
        let step = 9;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 10;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 11;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 12;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
            this.showVideo(2);
        }
        // @ts-ignore
        let step = 13;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 14;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
            this.showVideo(3);
        }
        // @ts-ignore
        let step = 15;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 16;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        const step = 17;
        // @ts-ignore
        if (Math.round(video.currentTime) > this.time * step - 1.75 && (this.step === step || this.step === 16)) {
            this.doThing(step);
        }
        window.requestAnimationFrame(this.onTimeUpdate.bind(this));
    }

    doThing(step) {
        this.discoverElement2 = true;
        this.text[step - 1] = false;
        this.text[step] = true;
        this.text[step + 1] = false;
        this.step = step;
        setTimeout(() => {
            this.discoverElement2 = false;
        }, 1300);
    }

    goTo(where: string) {
        const scrolling = document.getElementById('content');
        scrolling.removeEventListener('scroll', this.eventLister, false);
        scrolling.removeEventListener('mousewheel', this.eventLister, false);
        scrolling.removeEventListener('touchmove', this.eventLister, false);
        this.showVideoVar = false;
        setTimeout(() => {
            this.store$.dispatch(new LoadingStoreActions.ComponentLoadAction({component: where}));
        }, 1000);
    }

    end() {
        this.goTo('video-player-3');
    }

    show() {
        this.easterEgg = true;
        if (!this.hasClickEG) {
            this.store$.dispatch(new LoadingStoreActions.SetCounterItemsAction({
                counter: this.counter + 1
            }));
        }
        this.hasClickEG = true;
        this.isScrolling2 = true;
        setTimeout(() => {
            this.isScrolling2 = false;
            // @ts-ignore
            vid.currentTime = vid.currentTime + 0.1;
        }, 1500);
        this.modalService.init(ModalVideoComponent, {videoSrc2: 'assets/videos/EASTER_EGG_1', audioPlayP: this.audioPlay}, {});
    }

    // tslint:disable-next-line:variable-name
    showVideo(number) {
        this.isScrolling2 = true;
        setTimeout(() => {
            this.isScrolling2 = false;
        }, 3000);
        this.modalService.init(ModalVideoRoundComponent, {videoSrc2: 'assets/videos/VIDEO-' + number, audioPlayP: this.audioPlay}, {});
    }

    pauseMusic() {
        const audio = document.getElementById('audio');
        // @ts-ignore
        if (this.audioPlay === false) {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: true
            }));
            this.audioPlay = true;
            // @ts-ignore
            audio.play();
        } else {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: false
            }));
            this.audioPlay = false;
            // @ts-ignore
            audio.pause();
        }
    }

    playMusic() {
        const audio = document.getElementById('audio');
        // @ts-ignore
        this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
            audioPlay: true
        }));
        this.audioPlay = true;
        // @ts-ignore
        audio.play();
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }

}
