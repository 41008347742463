import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOADING_REQUEST = '[Loading] Loading Request',
  LOADING_SUCCESS = '[Loading] Loading Success',
  LOADING_FAILURE = '[Loading] Loading Failure',
  COMPONENT_LOAD = '[Loading] Component to load update',
  SET_I18N = '[Loading] Set I18N',
  SET_COUNTER_ITEMS = '[Loading] Set Counter Items',
  SET_COUNTER_KEYWORDS = '[Loading] Set Counter KeyWords',
  SET_VIDEO_SRC = '[Loading] Set Video Src',
  SET_VIDEO_SRC_2 = '[Loading] Set Video Src 2',
  SET_AUDIO_PLAY = '[Loading] Set audioPLay',
  SET_SCROLLING = '[Loading] Set Scrolling'
}

export class LoadingRequestAction implements Action {
  readonly type = ActionTypes.LOADING_REQUEST;
  constructor(public payload: {}) { }
}
export class LoadingFailureAction implements Action {
  readonly type = ActionTypes.LOADING_FAILURE;
  constructor(public payload: { error: string }) { }
}
export class LoadingSuccessAction implements Action {
  readonly type = ActionTypes.LOADING_SUCCESS;
  constructor(public payload: {}) { }
}
export class ComponentLoadAction implements Action {
  readonly type = ActionTypes.COMPONENT_LOAD;
  constructor(public payload: { component: string }) { }
}
export class SetCounterItemsAction implements Action {
  readonly type = ActionTypes.SET_COUNTER_ITEMS;
  constructor(public payload: { counter: number }) { }
}
export class SetAudioPlay implements Action {
  readonly type = ActionTypes.SET_AUDIO_PLAY;
  constructor(public payload: {audioPlay: boolean}) { }
}
export class SetIsScrolling implements Action {
  readonly type = ActionTypes.SET_SCROLLING;
  constructor(public payload: {allowScroll: boolean}) { }
}
export class SetVideoSrcAction implements Action {
  readonly type = ActionTypes.SET_VIDEO_SRC;
  constructor(public payload: { videoSrc: string }) { }
}
export class SetVideoSrcAction2 implements Action {
  readonly type = ActionTypes.SET_VIDEO_SRC_2;
  constructor(public payload: { videoSrc2: string }) { }
}


export type Actions = LoadingRequestAction | LoadingSuccessAction | LoadingFailureAction |
  ComponentLoadAction |  SetCounterItemsAction | SetVideoSrcAction | SetVideoSrcAction2 | SetIsScrolling | SetAudioPlay ;
