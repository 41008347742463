import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {TranslateService} from '../../providers/translate/translate.service';
import {LoadingStoreActions, LoadingStoreSelectors, RootStoreState} from '../../reducers';
import {animate, style, transition, trigger} from '@angular/animations';
import {ModalService} from '../../providers/modal/modal-service.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {NguCarouselConfig} from '@ngu/carousel';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-video-player-6',
    templateUrl: './video-player-6.component.html',
    styleUrls: ['./video-player-6.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.20s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.20s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.40s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.50s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '12px'}),
                        animate('0.50s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        )
    ]
})


export class VideoPlayer6Component implements OnInit, OnDestroy {
    private subs: Array<Subscription> = [];
    videoSrc: string;
    step: number;
    counter: number;
    audioPlay: boolean;
    allowScroll: boolean;
    game1: boolean;
    game2: boolean;
    game3: boolean;
    game4: boolean;
    game5: boolean;
    citations: any;
    sources: any;
    intro: boolean;
    outro: boolean;
    pillar: number;
    carouselConfig: NguCarouselConfig;
    carouselItems: any;
    carouselItems2: any;
    eventListermouseup1: any;
    eventListermouseup2: any;
    eventLister1: any;
    eventLister2: any;
    mouseUp: boolean;
    eventListermousedown1: any;
    eventListermousedown2: any;
    clientY: any;
    mobileLock: boolean;
    DragDropWords: any;
    DragDropWordsNOk: any;
    DragDropWordsOk: any;
    DragDropWordsAll: any;
    todoList: any;
    carouselG5ItemsText: any;
    carouselG5ItemsTextSelected: any;
    g5currentstep: any;
    points: any;
    nguindex: any;
    clickOn: number;
    pillar1: boolean;
    pillar2: boolean;
    showVideoVar: boolean;
    pillar3: boolean;
    list: any;
    cont1: any;
    cont2: any;
    cont3: any;
    cont4: any;
    cont5: any;
    cont6: any;
    tempData: string;
    listg5: any;
    g51: any;
    g52: any;
    g53: any;
    g54: any;
    listg1: any;
    listg2: any;
    listg3: any;
    listg4: any;

    constructor(
        private translateService: TranslateService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
        private store$: Store<RootStoreState.State>) {
        this.step = 0;
        this.videoSrc = '';
        this.intro = true;
        this.game1 = false;
        this.game2 = false;
        this.game3 = false;
        this.game4 = false;
        this.game5 = false;
        this.outro = false;
        this.pillar = 0;
        this.mouseUp = true;
        this.clientY = null;
        this.showVideoVar = true;
        this.points = 0;
        this.citations = ['WISE', 'BE', 'WILD', 'BE '];
        this.list = ['KEEP IMAGINING', 'PATH UNEXPLORED', 'FREE SPIRIT', 'YOUNG SOUL', 'POSITIVE MINDSET'];
        this.cont1 = [];
        this.cont2 = [];
        this.cont3 = [];
        this.cont4 = [];
        this.cont5 = [];
        this.cont6 = [];
        this.carouselItems = ['DESIRE', 'MASTERY', 'INTUITION'];
        this.carouselItems2 = ['VEHICULE', 'COMPASS', 'ENERGY'];
        this.eventLister1 = this.handleEvent.bind(this);
        this.eventLister2 = this.handleEvent.bind(this);
        this.eventListermouseup1 = this.handleEventMouseup.bind(this);
        this.eventListermouseup2 = this.handleEventMouseup.bind(this);
        this.eventListermousedown1 = this.handleEventMousedown.bind(this);
        this.eventListermousedown2 = this.handleEventMousedown.bind(this);
        this.mobileLock = false;
        this.DragDropWordsAll = ['REAL CRAFTMANSHIP', 'OLD WORLD', 'FREEDOM', 'DIGITAL ARTISTRY', 'TRADITION', 'DREAMLIKE WORLD'];
        this.DragDropWords = ['YOUTH'];
        this.DragDropWordsNOk = [];
        this.DragDropWordsOk = [];
        this.todoList = ['root', 'child1', 'child2'];
        this.nguindex = 0;
        this.clickOn = 0;
        this.carouselConfig = {
            grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
            load: 1,
            loop: true,
            touch: true,
            velocity: 0.5,
        };
        this.carouselG5ItemsText = ['REAL CRAFTMANSHIP', 'YOUNG SOUL', 'DIGITAL ARTISTRY', 'FREE'];
        this.carouselG5ItemsTextSelected = ['REAL CRAFTMANSHIP'];
        this.g5currentstep = 0;
        this.pillar1 = false;
        this.pillar2 = false;
        this.pillar3 = false;
        this.tempData = null;
        this.g51 = [];
        this.g52 = [];
        this.g53 = [];
        this.g54 = [];
        this.listg1 = [1];
        this.listg2 = [2];
        this.listg3 = [3];
        this.listg4 = [4];
        this.listg5 = ['img-g5-1', 'img-g5-2', 'img-g5-3', 'img-g5-4'];
    }

    ngOnInit() {
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectVideoSrc).subscribe((src) => {
            if (src) {
                this.videoSrc = src;
            }
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectCounterItems).subscribe((counterItem: number) => {
            this.counter = counterItem;
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectAudioPlay).subscribe((audioPlay: boolean) => {
            this.audioPlay = audioPlay;
        }));
    }

    drop2(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            //nothing
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    handleEvent(e) {
        e.preventDefault();
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!this.mouseUp || (isMobile && !this.mobileLock)) {
            if (!this.clientY) {
                if (isMobile) {
                    this.clientY = e.changedTouches ? e.changedTouches[0].clientY : null;
                } else {
                    this.clientY = e.clientY;
                }
            } else {
                let changeHeight;
                if (isMobile) {
                    changeHeight = 30;
                } else {
                    changeHeight = 60;
                }
                this.mobileLock = true;
                const parentElement = e.target.parentNode.parentNode.querySelector('.nguvertical');
                // tslint:disable-next-line:radix
                const top = parentElement.style.getPropertyValue('top') ? parseInt(parentElement.style.getPropertyValue('top')) : 0;
                let newPositionY;
                if (isMobile) {
                    newPositionY = e.changedTouches[0].clientY;
                } else {
                    newPositionY = e.clientY;
                }
                if (this.clientY > newPositionY) {
                    if (this.checkTop((top - changeHeight), changeHeight)) {
                        e.target.parentNode.parentNode.querySelectorAll('.item').forEach(item => {
                            item.classList.remove('selected');
                        });
                        parentElement.style.top = '' + (top - changeHeight) + 'px';
                        if ((top - changeHeight) === 0) {
                            e.target.parentNode.parentNode.querySelectorAll('.item')[1].classList.add('selected');
                        } else {
                            e.target.parentNode.parentNode.querySelectorAll('.item')[2].classList.add('selected');
                        }
                    }
                } else {
                    if (this.checkTop((top + changeHeight), changeHeight)) {
                        e.target.parentNode.parentNode.querySelectorAll('.item').forEach(item => {
                            item.classList.remove('selected');
                        });
                        parentElement.style.top = '' + (top + changeHeight) + 'px';
                        if ((top + changeHeight) === 0) {
                            e.target.parentNode.parentNode.querySelectorAll('.item')[1].classList.add('selected');
                        } else {
                            e.target.parentNode.parentNode.querySelectorAll('.item')[0].classList.add('selected');
                        }
                    }

                }
                if (isMobile) {
                    setTimeout(() => {
                        this.mobileLock = false;
                    }, 250);
                }
                this.mouseUp = true;
                this.clientY = null;
                setTimeout(() => {
                    this.checkResultG3();
                }, 250);
            }
        }
    }

    onMoveFn(event) {
        this.nguindex = event.currentSlide;
    }

    checkTop(value, changeHeight) {
        return (value >= -changeHeight && value <= changeHeight) || value === 0;
    }

    checkResultG3() {
        let sentence = '';
        const selected = document.querySelectorAll('.selected');
        selected.forEach(item => {
            if (item.innerHTML !== undefined) {
                sentence += item.innerHTML;
            }
        });
        if (sentence.replace(/\s/g, '') === 'Desireenergy' && this.pillar1 === false) {
            this.pillar = this.pillar + 1;
            this.pillar1 = true;
            selected.forEach(item => {
                item.classList.add('founded');
            });
        }
        if (sentence.replace(/\s/g, '') === 'Intuitioncompass' && this.pillar2 === false) {
            this.pillar = this.pillar + 1;
            this.pillar2 = true;
            selected.forEach(item => {
                item.classList.add('founded');
            });
        }
        if (sentence.replace(/\s/g, '') === 'Masteryvehicule' && this.pillar3 === false) {
            this.pillar = this.pillar + 1;
            this.pillar3 = true;
            selected.forEach(item => {
                item.classList.add('founded');
            });
        }
        if (this.pillar === 3) {
            setTimeout(() => {
                this.endGame(2);
            }, 2000);
        }
    }

    handleEventMouseup(e) {
        e.preventDefault();
        this.mouseUp = true;
    }

    handleEventMousedown(e) {
        e.preventDefault();
        this.mouseUp = false;
    }

    ngAfterViewInit() {
        const vid = document.getElementById('backgroundVideo');
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            // @ts-ignore
            vid.play();
        } else {
            // @ts-ignore
            vid.play();
        }
        if (this.audioPlay) {
            const audio = document.getElementById('audio');
            // @ts-ignore
            audio.volume = 0.35;
            // @ts-ignore
            audio.play();
        }
        const logo = document.getElementById('logo');
        logo.classList.remove('hide');
    }

    dropA(event: CdkDragDrop<string[]>) {
        this.moveItemInArrayIfAllowed(this.citations, event.previousIndex, event.currentIndex);
    }

    dropD(event: CdkDragDrop<string[]>) {
        this.moveItemInArrayIfAllowed(this.list, event.previousIndex, event.currentIndex);
    }

    clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    moveItemInArrayIfAllowed(
        array: any[],
        fromIndex: number,
        toIndex: number
    ): void {
        const from = this.clamp(fromIndex, array.length - 1);
        const to = this.clamp(toIndex, array.length - 1);
        if (from === to) {
            return;
        }

        const target = array[from];
        const delta = to < from ? -1 : 1;

        const affectedItems = array.filter((item, index) =>
            delta > 0 ? index >= from && index <= to : index >= to && index <= from
        );

        // If any of the items affected by the index changes is disabled
        // don't move any of the items.
        if (affectedItems.some((i) => i.disabled)) {
            return;
        }

        for (let i = from; i !== to; i += delta) {
            array[i] = array[i + delta];
        }

        array[to] = target;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            let trans = false;
            if (event.previousContainer.data[0] === 'YOUTH' && event.container.id === 'cdk-drop-list-12') {
                trans = true;
                this.DragDropWords.push('REAL CRAFTMANSHIP');
            }
            if (event.previousContainer.data[0] === 'TRADITION' && event.container.id === 'cdk-drop-list-10') {
                trans = true;
                this.DragDropWords.push('OLD WORLD');
            }
            if (event.previousContainer.data[0] === 'OLD WORLD' && event.container.id === 'cdk-drop-list-10') {
                trans = true;
                this.DragDropWords.push('FREEDOM');
            }
            if (event.previousContainer.data[0] === 'DREAMLIKE WORLD' && event.container.id === 'cdk-drop-list-12') {
                trans = true;
            }
            if (event.previousContainer.data[0] === 'REAL CRAFTMANSHIP' && event.container.id === 'cdk-drop-list-12') {
                trans = true;
                this.DragDropWords.push('TRADITION');
            }
            if (event.previousContainer.data[0] === 'DIGITAL ARTISTRY' && event.container.id === 'cdk-drop-list-12') {
                trans = true;
                this.DragDropWords.push('DREAMLIKE WORLD');
            }
            if (event.previousContainer.data[0] === 'FREEDOM' && event.container.id === 'cdk-drop-list-12') {
                trans = true;
                this.DragDropWords.push('DIGITAL ARTISTRY');
            }

            if (trans) {
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
            }
        }

        this.checkGame4();
    }

    drop7(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            if (event.container.data.length > 0 && event.previousContainer.data.length > 0) {
                const temp1 = event.container.data[0];
                const temp2 = event.previousContainer.data[0];
                event.container.data.splice(0, 1);
                event.previousContainer.data.splice(0, 1);
                event.container.data.push(temp2);
                event.previousContainer.data.push(temp1);
            } else {
                // @ts-ignore
                if ((event.container.id === 'cdk-drop-list-13' && event.previousContainer.data[0] === 1)
                    // @ts-ignore
                    || (event.container.id === 'cdk-drop-list-14' && event.previousContainer.data[0] === 3)
                    // @ts-ignore
                    || (event.container.id === 'cdk-drop-list-15' && event.previousContainer.data[0] === 4)
                    // @ts-ignore
                    || (event.container.id === 'cdk-drop-list-16' && event.previousContainer.data[0] === 2)
                ) {
                    transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                }

            }
        }


        setTimeout(() => {
            if (document.querySelectorAll('.game5 .round-item.ok').length === 4) {
                setTimeout(() => {
                    this.endGame(5);
                }, 1000);
            }
        }, 1000);
    }

    checkGame4() {
        if (this.DragDropWords.length === 0) {
            setTimeout(() => {
                this.endGame(4);
            }, 1000);
        }
    }

    onClickG5() {
        if (this.g5currentstep === 0 && this.nguindex === 3) {
            this.points = this.points + 2;
        }
        if (this.g5currentstep === 1 && this.nguindex === 1) {
            this.points = this.points + 2;
        }
        if (this.g5currentstep === 2 && this.nguindex === 2) {
            this.points = this.points + 2;
        }
        if (this.g5currentstep === 3 && this.nguindex === 0) {
            this.points = this.points + 2;
        }
        this.clickOn = this.clickOn + 1;
        this.g5currentstep = this.g5currentstep + 1;
        if (this.clickOn === 4) {
            setTimeout(() => {
                this.endGame(5);
            }, 2000);
        } else {
            this.carouselG5ItemsTextSelected = [];
            this.carouselG5ItemsTextSelected = [this.carouselG5ItemsText[this.g5currentstep]];
        }
    }

    checkIfGood(element) {
        let result = '';
        // tslint:disable-next-line:only-arrow-functions
        this.citations.forEach(function (item, index) {
            switch (item) {
                case 'BE':
                    if (index === 0 && element === 'BE') {
                        result = 'ok';
                    } else if (element === 'BE') {
                        result = 'ko';
                    }
                    break;
                case 'WILD':
                    if (index === 1 && element === 'WILD') {
                        result = 'ok';
                    } else if (element === 'WILD') {
                        result = 'ko';
                    }
                    break;
                case 'BE ':
                    if (index === 2 && element === 'BE ') {
                        result = 'ok';
                    } else if (element === 'BE ') {
                        result = 'ko';
                    }
                    break;
                case 'WISE':
                    if (index === 3 && element === 'WISE') {
                        result = 'ok';
                    } else if (element === 'WISE') {
                        result = 'ko';
                    }
                    break;
            }
        });

        if (document.querySelectorAll('.game1 .list .box.ok').length === 4) {
            setTimeout(() => {
                this.endGame(1);
            }, 1000);
        }

        return result;
    }

    checkIfGood5(element) {
        let result = '';
        // tslint:disable-next-line:only-arrow-functions
        this.list.forEach(function (item, index) {
            switch (item) {
                case 'PATH UNEXPLORED':
                    if (index === 0 && element === 'PATH UNEXPLORED') {
                        result = 'ok';
                    } else if (element === 'PATH UNEXPLORED') {
                        result = 'ko';
                    }
                    break;
                case 'POSITIVE MINDSET':
                    if (index === 1 && element === 'POSITIVE MINDSET') {
                        result = 'ok';
                    } else if (element === 'POSITIVE MINDSET') {
                        result = 'ko';
                    }
                    break;
                case 'YOUNG SOUL':
                    if (index === 2 && element === 'YOUNG SOUL') {
                        result = 'ok';
                    } else if (element === 'YOUNG SOUL') {
                        result = 'ko';
                    }
                    break;
                case 'KEEP IMAGINING':
                    if (index === 3 && element === 'KEEP IMAGINING') {
                        result = 'ok';
                    } else if (element === 'KEEP IMAGINING') {
                        result = 'ko';
                    }
                    break;
                case 'FREE SPIRIT':
                    if (index === 4 && element === 'FREE SPIRIT') {
                        result = 'ok';
                    } else if (element === 'FREE SPIRIT') {
                        result = 'ko';
                    }
                    break;
            }
        });

        if (document.querySelectorAll('.game3 .list .box.ok').length === 5) {
            setTimeout(() => {
                this.endGame(3);
            }, 1000);
        }

        return result;
    }

    checkIfGood2(element) {
        let result = '';
        // tslint:disable-next-line:only-arrow-functions
        switch (element) {
            case 'PATH UNEXPLORED':
                if (this.cont3.includes(element)) {
                    result = 'ok';
                } else if (element === 'PATH UNEXPLORED') {
                    result = 'ko';
                }
                break;
            case 'POSITIVE MINDSET':
                if (this.cont1.includes(element)) {
                    result = 'ok';
                } else if (element === 'POSITIVE MINDSET') {
                    result = 'ko';
                }
                break;
            case 'YOUNG SOUL':
                if (this.cont4.includes(element)) {
                    result = 'ok';
                } else if (element === 'YOUNG SOUL') {
                    result = 'ko';
                }
                break;
            case 'KEEP IMAGINING':
                if (this.cont5.includes(element)) {
                    result = 'ok';
                } else if (element === 'KEEP IMAGINING') {
                    result = 'ko';
                }
                break;
            case 'FREE SPIRIT':
                if (this.cont2.includes(element)) {
                    result = 'ok';
                } else if (element === 'FREE SPIRIT') {
                    result = 'ko';
                }
                break;
        }
        if (document.querySelectorAll('.game3 .bloc2 .ok').length === 5) {
            setTimeout(() => {
                this.endGame(1);
            }, 1000);
        }
        return result;
    }

    checkIfGood3(element) {
        let result = '';
        // tslint:disable-next-line:only-arrow-functions
        switch (element) {
            case 'DESIRE':
                if (
                    (this.cont1.includes('DESIRE') && this.cont2.includes('ENERGY')) ||
                    (this.cont3.includes('DESIRE') && this.cont4.includes('ENERGY')) ||
                    (this.cont5.includes('DESIRE') && this.cont6.includes('ENERGY'))
                ) {
                    result = 'ok';
                } else if (element === 'DESIRE') {
                    result = 'ko';
                }
                break;
            case 'MASTERY':
                if (
                    (this.cont1.includes('MASTERY') && this.cont2.includes('VEHICULE')) ||
                    (this.cont3.includes('MASTERY') && this.cont4.includes('VEHICULE')) ||
                    (this.cont5.includes('MASTERY') && this.cont6.includes('VEHICULE'))
                ) {
                    result = 'ok';
                } else if (element === 'MASTERY') {
                    result = 'ko';
                }
                break;
            case 'INTUITION':
                if (
                    (this.cont1.includes('INTUITION') && this.cont2.includes('COMPASS')) ||
                    (this.cont3.includes('INTUITION') && this.cont4.includes('COMPASS')) ||
                    (this.cont5.includes('INTUITION') && this.cont6.includes('COMPASS'))
                ) {
                    result = 'ok';
                } else if (element === 'INTUITION') {
                    result = 'ko';
                }
                break;
            case 'VEHICULE':
                if (
                    (this.cont1.includes('MASTERY') && this.cont2.includes('VEHICULE')) ||
                    (this.cont3.includes('MASTERY') && this.cont4.includes('VEHICULE')) ||
                    (this.cont5.includes('MASTERY') && this.cont6.includes('VEHICULE'))
                ) {
                    result = 'ok';
                } else if (element === 'VEHICULE') {
                    result = 'ko';
                }
                break;
            case 'COMPASS':
                if (
                    (this.cont1.includes('INTUITION') && this.cont2.includes('COMPASS')) ||
                    (this.cont3.includes('INTUITION') && this.cont4.includes('COMPASS')) ||
                    (this.cont5.includes('INTUITION') && this.cont6.includes('COMPASS'))
                ) {
                    result = 'ok';
                } else if (element === 'COMPASS') {
                    result = 'ko';
                }
                break;
            case 'ENERGY':
                if (
                    (this.cont1.includes('DESIRE') && this.cont2.includes('ENERGY')) ||
                    (this.cont3.includes('DESIRE') && this.cont4.includes('ENERGY')) ||
                    (this.cont5.includes('DESIRE') && this.cont6.includes('ENERGY'))
                ) {
                    result = 'ok';
                } else if (element === 'ENERGY') {
                    result = 'ko';
                }
                break;
        }
        if (document.querySelectorAll('.game2 .bloc2 .ok').length === 6) {
            setTimeout(() => {
                this.endGame(2);
            }, 1000);
        }
        return result;
    }

    game2fct() {
        const touch = document.getElementsByClassName('nguvertical');
        if (touch && touch[0]) {
            touch[0].addEventListener('touchmove', this.eventLister1, false);
            touch[0].addEventListener('mousedown', this.eventListermousedown1, false);
            touch[0].addEventListener('mouseup', this.eventListermouseup1, false);
            touch[0].addEventListener('mousemove', this.eventLister1, false);
        }
        if (touch && touch[1]) {
            touch[1].addEventListener('touchmove', this.eventLister2, false);
            touch[1].addEventListener('mousedown', this.eventListermousedown2, false);
            touch[1].addEventListener('mouseup', this.eventListermouseup2, false);
            touch[1].addEventListener('mousemove', this.eventLister2, false);
        }
    }

    canDrop1() {
        if (this.cont1.length && (
            (this.cont1.includes('DESIRE') && this.cont2.includes('ENERGY')) ||
            (this.cont1.includes('MASTERY') && this.cont2.includes('VEHICULE')) ||
            (this.cont1.includes('INTUITION') && this.cont2.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    canDrop2() {
        if (this.cont2.length && (
            (this.cont1.includes('DESIRE') && this.cont2.includes('ENERGY')) ||
            (this.cont1.includes('MASTERY') && this.cont2.includes('VEHICULE')) ||
            (this.cont1.includes('INTUITION') && this.cont2.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    canDrop3() {
        if (this.cont3.length && (
            (this.cont3.includes('DESIRE') && this.cont4.includes('ENERGY')) ||
            (this.cont3.includes('MASTERY') && this.cont4.includes('VEHICULE')) ||
            (this.cont3.includes('INTUITION') && this.cont4.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    canDrop4() {
        if (this.cont4.length && (
            (this.cont3.includes('DESIRE') && this.cont4.includes('ENERGY')) ||
            (this.cont3.includes('MASTERY') && this.cont4.includes('VEHICULE')) ||
            (this.cont3.includes('INTUITION') && this.cont4.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    canDrop5() {
        if (this.cont2.length && (
            (this.cont5.includes('DESIRE') && this.cont6.includes('ENERGY')) ||
            (this.cont5.includes('MASTERY') && this.cont6.includes('VEHICULE')) ||
            (this.cont5.includes('INTUITION') && this.cont6.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    canDrop6() {
        if (this.cont6.length && (
            (this.cont5.includes('DESIRE') && this.cont6.includes('ENERGY')) ||
            (this.cont5.includes('MASTERY') && this.cont6.includes('VEHICULE')) ||
            (this.cont5.includes('INTUITION') && this.cont6.includes('COMPASS'))
        )
        ) {
            return false;
        } else {
            return true;
        }
    }

    enter(event: CdkDragDrop<string[]>) {
        if (event.container.data.length > 0) {
            this.tempData = event.container.data[0];
            event.container.data.splice(0, 1);
            if (this.tempData === 'DESIRE' || this.tempData === 'INTUITION' || this.tempData === 'MASTERY') {
                if (!this.carouselItems.includes(this.tempData)) {
                    this.carouselItems.push(this.tempData);
                }
            }
            if (this.tempData === 'VEHICULE' || this.tempData === 'COMPASS' || this.tempData === 'ENERGY') {
                if (!this.carouselItems2.includes(this.tempData)) {
                    this.carouselItems2.push(this.tempData);
                }
            }
        }
    }

    exit(event: CdkDragDrop<string[]>) {
        if (this.tempData != null) {
            if (this.tempData === 'DESIRE' || this.tempData === 'INTUITION' || this.tempData === 'MASTERY') {
                if (this.carouselItems.includes(this.tempData)) {
                    // this.carouselItems.splice(this.carouselItems.indexOf(this.tempData), 1);
                } else if (!this.carouselItems.includes(this.tempData) && this.tempData !== event.item.element.nativeElement.innerHTML) {
                    this.carouselItems.push(this.tempData);
                }
            }
            if (this.tempData === 'VEHICULE' || this.tempData === 'COMPASS' || this.tempData === 'ENERGY') {
                if (this.carouselItems2.includes(this.tempData)) {
                    // this.carouselItems2.splice(this.carouselItems.indexOf(this.tempData), 1);
                } else if (!this.carouselItems2.includes(this.tempData) && this.tempData !== event.item.element.nativeElement.innerHTML) {
                    this.carouselItems2.push(this.tempData);
                }
            }
            this.tempData = null;
        }
    }

    enter2(event: CdkDragDrop<string[]>) {
        if (document.querySelectorAll('.game5 .round-item.ok').length === 3 || document.querySelectorAll('.game5 .round-item.ok').length === 4) {
            setTimeout(() => {
                this.endGame(5);
            }, 1000);
        }
    }

    exit2(event: CdkDragDrop<string[]>) {
        if (document.querySelectorAll('.game5 .round-item.ok').length === 3 || document.querySelectorAll('.game5 .round-item.ok').length === 4) {
            setTimeout(() => {
                this.endGame(5);
            }, 1000);
        }
    }

    enterColor(event: CdkDragDrop<string[]>) {
        if (this.DragDropWords[0] === 'YOUTH' && event.container.id === 'cdk-drop-list-12') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'TRADITION' && event.container.id === 'cdk-drop-list-10') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'OLD WORLD' && event.container.id === 'cdk-drop-list-10') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'DREAMLIKE WORLD' && event.container.id === 'cdk-drop-list-12') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'REAL CRAFTMANSHIP' && event.container.id === 'cdk-drop-list-12') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'DIGITAL ARTISTRY' && event.container.id === 'cdk-drop-list-12') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
        if (this.DragDropWords[0] === 'FREEDOM' && event.container.id === 'cdk-drop-list-12') {
            document.getElementsByClassName('cdk-drag-preview')[0].classList.add('ok');
        }
    }

    exitColor(event: CdkDragDrop<string[]>) {
        document.getElementsByClassName('cdk-drag-preview')[0].classList.remove('ok');
    }

    drop6(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            //nothing
        } else {
            if (event.container.data.length > 0 && event.previousContainer.data.length > 0) {
                const temp1 = event.container.data[0];
                const temp2 = event.previousContainer.data[0];
                event.container.data.splice(0, 1);
                event.previousContainer.data.splice(0, 1);
                event.container.data.push(temp2);
                event.previousContainer.data.push(temp1);
            } else {
                if (event.container.data.length === 1) {
                    if (this.tempData === 'DESIRE' || this.tempData === 'INTUITION' || this.tempData === 'MASTERY') {
                        this.carouselItems.push(event.container.data[0]);
                    }
                    if (this.tempData === 'VEHICULE' || this.tempData === 'COMPASS' || this.tempData === 'ENERGY') {
                        this.carouselItems2.push(event.container.data[0]);
                    }
                    event.container.data.splice(0, 1);
                }
                transferArrayItem(event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex);
                if (event.container.data.length === 2) {
                    event.container.data.splice(1, 1);
                }
            }
        }
    }

    start() {
        this.intro = false;
        this.game1 = true;
    }

    restart() {
        this.store$.dispatch(new LoadingStoreActions.ComponentLoadAction({component: 'video-player'}));
    }

    getCounter() {
        return ' assets/images/COLLECTEUR_' + this.counter + '_4.svg';
    }

    endGame(game) {
        switch (game) {
            case 1:
                this.game1 = false;
                this.game2 = true;
                this.game2fct();
                break;
            case 2:
                this.game2 = false;
                this.game3 = true;
                break;
            case 3:
                this.game3 = false;
                this.game4 = true;
                break;
            case 4:
                this.calculateResult();
                this.game4 = false;
                this.game5 = true;
                break;
            case 5:
                const vid = document.getElementById('backgroundVideo');
                vid.style.display = 'none';
                const vid2 = document.getElementById('backgroundVideo2');
                vid2.style.display = 'block';
                // @ts-ignore
                vid2.play();
                this.game5 = false;
                this.outro = true;
                break;
        }
    }

    calculateResult() {
        this.DragDropWordsNOk.forEach(item => {
            if (item === 'OLD WORLD' || item === 'TRADITION') {
                this.points = this.points + 1;
            }
        });
        this.DragDropWordsOk.forEach(item => {
            if (item === 'DREAMLIKE WORLD' || item === 'REAL CRAFTMANSHIP' || item === 'DIGITAL ARTISTRY' || item === 'FREEDOM' || item === 'YOUTH') {
                this.points = this.points + 1;
            }
        });
    }

    pauseMusic() {
        const audio = document.getElementById('audio');
        // @ts-ignore
        if (this.audioPlay === false) {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: true
            }));
            // @ts-ignore
            audio.play();
        } else {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: false
            }));
            // @ts-ignore
            audio.pause();
        }
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }

}
