import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../providers/translate/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(key: string): any {
    return this.byString(this.translate.data, key);
  }

  byString = (o: object, s: string) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return {};
      }
    }
    return o;
  }
}
