import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import {VideoPlayer3Component} from './video-player-3.component';
import {ModalVideoComponent} from '../../components/modals/modal-video/modal-video.component';
import {TranslatePipe3} from '../../pipes/translate/translate3.pipe';
import {ModalVideoRoundComponent} from '../../components/modals/modal-video-round/modal-video-round.component';

@NgModule({
    declarations: [VideoPlayer3Component, TranslatePipe3],
    imports: [
        CommonModule,
        FormsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    exports: [VideoPlayer3Component],
    entryComponents: [
        ModalVideoComponent,
        ModalVideoRoundComponent
    ]
})
export class VideoPlayer3ComponentModule {
}
