import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {TranslateService} from '../../providers/translate/translate.service';
import {LoadingStoreActions, LoadingStoreSelectors, RootStoreState} from '../../reducers';
import {animate, style, transition, trigger} from '@angular/animations';
import {ModalService} from '../../providers/modal/modal-service.service';
import {ModalVideoComponent} from '../../components/modals/modal-video/modal-video.component';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.20s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.20s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationEaster',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('1s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.5s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.50s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '28px'}),
                        animate('0.75s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte2',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '20px'}),
                        animate('0.75s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'inOutAnimationTexte3',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0, 'margin-top': '30px'}),
                        animate('2s ease-out',
                            style({opacity: 1, 'margin-top': '0px'}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.230s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
    ]
})


export class VideoPlayerComponent implements OnInit, OnDestroy {
    private subs: Array<Subscription> = [];
    videoSrc: string;
    text = {
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    };
    last: number;
    duration: number;
    tmpYOffset: number;
    isScrolling: boolean;
    totalElement: number;
    step: number;
    enterClic: boolean;
    discoverElement: boolean;
    adv: number;
    easterEgg: boolean;
    time: number;
    eventLister: any;
    audioPlay: boolean;
    showVideoVar: boolean;
    counter: number;
    allowScroll: boolean;
    hasClickEG: boolean;
    ts: number;
    clickEaster: boolean;
    clientY: any;
    displayScroll: boolean;

    constructor(
        private translateService: TranslateService,
        private modalService: ModalService,
        private store$: Store<RootStoreState.State>) {
        this.videoSrc = '';
        this.duration = 0;
        this.tmpYOffset = 0;
        this.isScrolling = false;
        this.totalElement = 5;
        this.step = 0;
        this.enterClic = false;
        this.discoverElement = false;
        this.adv = 0;
        this.easterEgg = false;
        this.time = 0;
        this.eventLister = this.handleEvent.bind(this);
        this.showVideoVar = true;
        this.hasClickEG = false;
        this.ts = 0;
        this.clickEaster = false;
        this.clientY = null;
        this.audioPlay = false;
        this.displayScroll = false;
    }

    ngOnInit() {
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectVideoSrc).subscribe((src) => {
            if (src) {
                this.videoSrc = src;
            }
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectCounterItems).subscribe((counterItem: number) => {
            this.counter = counterItem;
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectIsScrolling).subscribe((allowScroll: boolean) => {
            this.allowScroll = allowScroll;
        }));
        this.subs.push(this.store$.select(LoadingStoreSelectors.selectAudioPlay).subscribe((audioPlay: boolean) => {
            this.audioPlay = audioPlay;
        }));

    }


    getCounter() {
        return ' assets/images/COLLECTEUR_' + this.counter + '_4.svg';
    }

    handleEvent(e) {
        e.preventDefault();
        const locked = document.getElementById('content').classList.contains('locked');
        let direction;
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            if (!this.clientY) {
                this.clientY =  e.changedTouches ? e.changedTouches[0].clientY : null;
                this.isScrolling = true;
            } else {
                this.isScrolling = false;
                if (this.clientY > e.changedTouches[0].clientY) {
                    direction = 'down';
                } else {
                    direction = 'up';
                }
                this.clientY = e.changedTouches[0].clientY;
            }
        } else {
            direction = e.wheelDelta > 0 ? 'up' : 'down';
        }
        if (!this.isScrolling && this.enterClic && !this.discoverElement && !locked) {
            const video = document.getElementById('backgroundVideo');
            // @ts-ignore
            if (this.step !== this.totalElement + 1 && video.currentTime > 0.6) {
                this.hideText();
                this.hasClickEG = false;
            }
            let timeout = 60;
            let timeout2 = 60;
            if (isMobile) {
                timeout = 250;
                timeout2 = 150;
            }
            const vid = document.getElementById('backgroundVideo');
            vid.style.filter = 'opacity(75%)';
            const logo = document.getElementById('logo');
            logo.style.filter = 'opacity(100%)';
            const steps = document.getElementById('steps');
            steps.style.filter = 'opacity(100%)';
            const sound = document.getElementById('sound1');
            if (sound) {
                sound.style.filter = 'opacity(100%)';
            }
            const sound2 = document.getElementById('sound2');
            if (sound2) {
                sound2.style.filter = 'opacity(100%)';
            }
            // this.isScrolling = true;
            // @ts-ignore
            if (!isNaN(vid.duration) && this.duration !== 0) {
                // @ts-ignore
                this.duration = vid.duration;
                this.time = this.duration / this.totalElement;
            }
            this.isScrolling = true;
            if (direction === 'up') {
                setTimeout(() => {
                    // @ts-ignore
                    vid.currentTime = vid.currentTime - 0.1;
                    if (this.step === 3 && !this.hasClickEG) {
                        const easter = document.getElementById('easter');
                        if (easter) {
                            easter.style.left = easter.offsetLeft - 2 + 'px';
                        }

                    }
                    // @ts-ignore
                    this.isScrolling = false;
                }, timeout);
            } else {
                setTimeout(() => {
                    this.isScrolling = false;
                    // @ts-ignore
                    vid.currentTime = vid.currentTime + 0.1;
                    if (this.step === 3 && !this.hasClickEG) {
                        const easter = document.getElementById('easter');
                        if (easter) {
                            easter.style.left = easter.offsetLeft + 2 + 'px';
                        }
                    }
                }, timeout2);
            }
        }
    }

    hideText() {
        let count = 0;
        // tslint:disable-next-line:forin
        for (const text in this.text) {
            if (this.step !== 6) {
                this.text[count] = false;
            }
            count = count + 1;
        }
    }

    ngAfterViewInit() {
        const vid = document.getElementById('backgroundVideo');
        // @ts-ignore
        this.duration = vid.duration;
        // @ts-ignore
        vid.muted = true;
        const scrolling = document.getElementById('content');
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            scrolling.addEventListener('wheel', this.eventLister, false);
        }
        scrolling.addEventListener('scroll', this.eventLister, false);
        scrolling.addEventListener('mousewheel', this.eventLister, false);
        scrolling.addEventListener('touchmove', this.eventLister, false);
        window.requestAnimationFrame(this.onTimeUpdate.bind(this));
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            // @ts-ignore
            vid.play();
            // @ts-ignore
            setTimeout(() => {
                // @ts-ignore
                vid.pause();
            }, 600);
        }
    }

    onTimeUpdate() {
        const video = document.getElementById('backgroundVideo');
        // @ts-ignore
        let step = 0;
        // @ts-ignore
        if (Math.round(video.currentTime) <= 0 && this.step !== step) {
            this.doThing(step);
        }
        // @ts-ignore
        let step = 1;
        // @ts-ignore
        if (Math.round(video.currentTime) >= this.time - 1 && Math.round(video.currentTime) < this.time && this.step !== step) {
            this.doThing(step);
            this.displayScroll = false;
        }
        // @ts-ignore
        let step = 2;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
            const logo = document.getElementById('logo');
            logo.style.filter = 'opacity(100%)';
            const steps = document.getElementById('steps');
            steps.style.filter = 'opacity(100%)';
            const sound = document.getElementById('sound1');
            if (sound) {
                sound.style.filter = 'opacity(100%)';
            }
            const sound2 = document.getElementById('sound2');
            if (sound2) {
                sound2.style.filter = 'opacity(100%)';
            }
        }
        // @ts-ignore
        let step = 3;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.doThing(step);
            const vid = document.getElementById('backgroundVideo');
            vid.style.filter = 'opacity(40%)';
            this.doThing(step);
            const logo = document.getElementById('logo');
            logo.style.filter = 'opacity(40%)';
            const steps = document.getElementById('steps');
            steps.style.filter = 'opacity(40%)';
            const sound = document.getElementById('sound1');
            if (sound) {
                sound.style.filter = 'opacity(40%)';
            }
            const sound2 = document.getElementById('sound2');
            if (sound2) {
                sound2.style.filter = 'opacity(40%)';
            }
        }
        // @ts-ignore
        let step = 4;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        if (Math.round(video.currentTime) > this.time * step - 1 && Math.round(video.currentTime) < this.time * step && this.step !== step) {
            this.isScrolling = true;
            setTimeout(() => {
                this.isScrolling = false;
                // @ts-ignore
                vid.currentTime = vid.currentTime + 0.1;
                if (this.step === 3 && !this.hasClickEG) {
                    const easter = document.getElementById('easter');
                    if (easter) {
                        easter.style.left = easter.offsetLeft + 2 + 'px';
                    }
                }
            }, 1500);
            const vid = document.getElementById('backgroundVideo');
            this.easterEgg = true;
            vid.style.filter = 'opacity(40%)';
            this.doThing(step);
            const logo = document.getElementById('logo');
            logo.style.filter = 'opacity(40%)';
            const steps = document.getElementById('steps');
            steps.style.filter = 'opacity(40%)';
            const sound = document.getElementById('sound1');
            if (sound) {
                sound.style.filter = 'opacity(40%)';
            }
            const sound2 = document.getElementById('sound2');
            if (sound2) {
                sound2.style.filter = 'opacity(40%)';
            }
        }
        // @ts-ignore
        const step = 5;
        // @ts-ignore
        if (Math.round(video.currentTime) > this.time * step - 2 && (this.step === step || this.step === 4)) {
            this.doThing(step);
            const logo = document.getElementById('logo');
            logo.style.filter = 'opacity(100%)';
            const steps = document.getElementById('steps');
            steps.style.filter = 'opacity(100%)';
            const sound = document.getElementById('sound1');
            if (sound) {
                sound.style.filter = 'opacity(100%)';
            }
            const sound2 = document.getElementById('sound2');
            if (sound2) {
                sound2.style.filter = 'opacity(100%)';
            }
        }
        window.requestAnimationFrame(this.onTimeUpdate.bind(this));
    }

    doThing(step) {
        this.discoverElement = true;
        this.text[step - 1] = false;
        this.text[step + 1] = true;
        this.text[step + 2] = false;
        this.step = step;
        setTimeout(() => {
            this.discoverElement = false;
        }, 1300);
    }

    touchstart(e) {
        this.ts = e.originalEvent.touches[0].clientY;
    }

    goTo(where: string) {
        const scrolling = document.getElementById('content');
        scrolling.removeEventListener('scroll', this.eventLister, false);
        scrolling.removeEventListener('mousewheel', this.eventLister, false);
        scrolling.removeEventListener('touchmove', this.eventLister, false);

        this.showVideoVar = false;
        setTimeout(() => {
            this.store$.dispatch(new LoadingStoreActions.ComponentLoadAction({component: where}));
        }, 1000);
    }

    enter() {
        this.text[0] = false;
        this.text[1] = true;
        this.enterClic = true;
        this.store$.dispatch(new LoadingStoreActions.SetIsScrolling({
            allowScroll: true
        }));
        this.audioPlay = false;
        this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
            audioPlay: true
        }));
        // const audio = document.getElementById('audio');
        // @ts-ignore
        // audio.volume = 0.35;
        // @ts-ignore
        // audio.play();
        const that = this;
        window.setTimeout(function() {
            that.displayScroll = true;
        }, 1000);
    }

    end() {
        this.goTo('video-player-2');
    }

    show() {
        this.easterEgg = true;
        if (!this.clickEaster) {
            this.store$.dispatch(new LoadingStoreActions.SetCounterItemsAction({
                counter: this.counter + 1
            }));
        }
        this.hasClickEG = true;
        this.clickEaster = true;
        this.isScrolling = true;
        setTimeout(() => {
            this.isScrolling = false;
        }, 1500);
        this.modalService.init(ModalVideoComponent, {
            videoSrc2: 'assets/videos/EASTER_EGG_0',
            audioPlayP: this.audioPlay
        }, {});
    }

    pauseMusic() {
        const audio = document.getElementById('audio');
        // @ts-ignore
        if (audio.paused && audio.currentTime > 0 && !audio.ended) {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: true
            }));
            this.audioPlay = true;
            // @ts-ignore
            audio.play();
        } else {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: false
            }));
            this.audioPlay = false;
            // @ts-ignore
            audio.pause();
        }
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
        this.subs = [];
    }

    playMusic() {
        const audio = document.getElementById('audio');
        // @ts-ignore
        this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
            audioPlay: true
        }));
        this.audioPlay = true;
        // @ts-ignore
        audio.play();
    }

}
