import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import {VideoPlayer6Component} from './video-player-6.component';
import {ModalVideoComponent} from '../../components/modals/modal-video/modal-video.component';
import {TranslatePipe6} from '../../pipes/translate/translate6.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NguCarouselModule } from '@ngu/carousel';

@NgModule({
    declarations: [VideoPlayer6Component, TranslatePipe6],
    imports: [
        CommonModule,
        FormsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragDropModule,
        NguCarouselModule
    ],
    exports: [VideoPlayer6Component],
    entryComponents: [
        ModalVideoComponent
    ]
})
export class VideoPlayer6ComponentModule {
}
