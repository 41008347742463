import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import {ModalService} from '../../../providers/modal/modal-service.service';
import {LoadingStoreActions, RootStoreState} from '../../../reducers';
import {TranslateService} from '../../../providers/translate/translate.service';
import {Subscription} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-modal-video',
    templateUrl: './modal-video.component.html',
    styleUrls: ['./modal-video.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({opacity: 0}),
                        animate('0.25s ease-out',
                            style({opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.25s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
        trigger(
            'OutAnimation',
            [
                transition(
                    ':leave',
                    [
                        style({opacity: 1}),
                        animate('0.25s ease-in',
                            style({opacity: 0}))
                    ]
                )
            ]
        ),
    ]
})
export class ModalVideoComponent implements OnInit {
    data: any;
    private subs: Array<Subscription> = [];
    public lgdata: any;
    videoSrc: string;
    videoSrc2: string;
    showVideo: boolean;
    audioPlayP: boolean;
    constructor(
        private modalService: ModalService,
        private translateService: TranslateService,
        private store$: Store<RootStoreState.State>) {
        this.videoSrc = '';
        this.showVideo = true;
    }

    ngOnInit() {
        document.getElementById('content').scrollTop = 0;
        if ((this as any).inputs && (this as any).inputs.videoSrc2) {
            this.videoSrc = (this as any).inputs.videoSrc2;
        }
        if ((this as any).inputs && (this as any).inputs.audioPlayP) {
            this.audioPlayP = (this as any).inputs.audioPlayP;
        }
        this.lgdata = this.translateService.data;
        const audio = document.getElementById('audio');
        // @ts-ignore
        audio.pause();
        document.getElementById('content').classList.add('locked');
        this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
            audioPlay: false
        }));
    }

    ngAfterViewInit() {
        if (this.videoSrc) {
            const vid = document.getElementById('video');
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                // @ts-ignore
                vid.controls = true;
            }
            // @ts-ignore
            vid.muted = false;
            // @ts-ignore
            vid.play();
        }
    }

    closeDialog() {
        const vid = document.getElementById('backgroundVideo');
        vid.style.filter = 'opacity(75%)';
        this.showVideo = false;
        document.getElementById('content').classList.remove('locked');
        const logo = document.getElementById('logo');
        logo.style.filter = 'opacity(100%)';
        const steps = document.getElementById('steps');
        if(steps) {
            steps.style.filter = 'opacity(100%)';
        }
        const sound = document.getElementById('sound1');
        if (sound) {
            sound.style.filter = 'opacity(100%)';
        }
        const sound2 = document.getElementById('sound2');
        if (sound2) {
            sound2.style.filter = 'opacity(100%)';
        }
        if (this.audioPlayP) {
            this.store$.dispatch(new LoadingStoreActions.SetAudioPlay({
                audioPlay: true
            }));
            const audio = document.getElementById('audio');
            // @ts-ignore
            audio.play();
        }
        setTimeout(() => {
            this.modalService.destroy();
        }, 1500);
    }

}
