import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, Inject, NgModule, PLATFORM_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {SpawnerDirective} from './directives/spawner.directive';
import {VideoPlayerComponent} from './pages/video-player/video-player.component';
import {VideoPlayer2Component} from './pages/video-player-2/video-player-2.component';
import {VideoPlayer3Component} from './pages/video-player-3/video-player-3.component';
import {VideoPlayerComponentModule} from './pages/video-player/video-player.component.module';
import {VideoPlayer2ComponentModule} from './pages/video-player-2/video-player-2.component.module';
import {VideoPlayer3ComponentModule} from './pages/video-player-3/video-player-3.component.module';
import {DomService} from './providers/modal/dom-service.service';
import {ModalService} from './providers/modal/modal-service.service';
import {ScriptService} from './providers/scripts.service';
import {TranslateService} from './providers/translate/translate.service';
import {RootStoreModule} from './reducers';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VideoPlayer5Component} from './pages/video-player-5/video-player-5.component';
import {VideoPlayer4Component} from './pages/video-player-4/video-player-4.component';
import {VideoPlayer6Component} from './pages/video-player-6/video-player-6.component';
import {VideoPlayer4ComponentModule} from './pages/video-player-4/video-player-4.component.module';
import {VideoPlayer5ComponentModule} from './pages/video-player-5/video-player-5.component.module';
import {VideoPlayer6ComponentModule} from './pages/video-player-6/video-player-6.component.module';

export function setupTranslateFactory(
    service: TranslateService): (() => void) {
    return () => {
        return;
    };
    // return () => service.use('fr');
}

@NgModule({
    declarations: [
        AppComponent,
        SpawnerDirective
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        HttpClientModule,
        RootStoreModule,
        VideoPlayerComponentModule,
        VideoPlayer2ComponentModule,
        VideoPlayer3ComponentModule,
        VideoPlayer4ComponentModule,
        VideoPlayer5ComponentModule,
        VideoPlayer6ComponentModule,
        BrowserAnimationsModule,
    ],
    providers: [
        TranslateService,
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [TranslateService],
            multi: true
        },
        ScriptService,
        ModalService,
        DomService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        VideoPlayerComponent,
        VideoPlayer2Component,
        VideoPlayer3Component,
        VideoPlayer4Component,
        VideoPlayer5Component,
        VideoPlayer6Component,
    ]
})
export class AppModule {
    constructor(@Inject(PLATFORM_ID) private readonly platformId: object) {
        // const platform = isPlatformBrowser(this.platformId) ?
        //   'in the browser' : 'on the server';
        // // console.log(`Running ${platform} with appId=${appId}`);
    }
}
